<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Panel>
        <template #header>
          Thông tin chung
        </template>
        <div class="p-card-field">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Họ tên(*)</label>
            <div class="p-col">
              <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Số điện thoại</label>
            <div class="p-col">
              <InputText   v-model="user.phone" id="phone_add" type="number" class="p-col-width" placeholder="Nhập số điện thoại"/>
            </div>
          </div>

          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Nhóm quyền (*)</label>
            <div class="p-col">
              <input class="input_tmp_validator" id="group_id_va">
              <Dropdown class="p-col-width" v-model="user.group_id" :options="list_group_role"
                        optionValue="id" optionLabel="name" placeholder="Chọn"/>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Email (*)</label>
            <div class="p-col">
              <InputText :disabled="mode == 'edit'" :required="mode == 'add'" v-model="user.email"  id="email" type="email" class="p-col-width" placeholder="Nhập email" />
              
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Mật khẩu <span v-if="mode == 'add'">(*)</span></label>
            <div class="p-col">
              <InputText :required="mode == 'add'" v-model="user.password"  id="password" type="password" class="p-col-width" placeholder="Nhập mật khẩu" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Nhập lại mật khẩu <span v-if="mode == 'add'">(*)</span></label>
            <div class="p-col">
              <InputText :required="mode == 'add'" v-model="user.password_confirm"  id="password_confirm" type="password" class="p-col-width" placeholder="Nhập lại mật khẩu" />
            </div>
          </div>
        </div>
      </Panel>
      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="p-button-success main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="p-button-success main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {

      user:{
        fullname: '',
        phone: '',
        email: '',
        password: '',
        password_confirm: '',
        group_id:null
      },
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {
    //var that =this;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model",this.model)

      this.model.email_old = this.model.email;

      var dataGroupUser = await this.getGroupUser(this.model.id);
      if (dataGroupUser && dataGroupUser.length){
        this.model.group_id = dataGroupUser[0].group_id;
      }
      this.user = this.$commonFuction.convertJsonObject(this.model);



      this.$CoreService.getAccountByUid(this.model.id).then(res => {
        console.log("getAccount",res)
        if (res && res.length){
          this.user.user_id = res[0].uid;
        }
      });
    }
    this.listGroupRole().then(res => {
      console.log("resres",res)
      this.list_group_role = res;
    });

  },
  methods: {
    async listGroupRole() {
      var where = {
        active: {_eq: true},
        deleted: {_eq: false}
      };
      var order_by = {id: 'desc'};
      var listData = await  this.$CoreService.getListData('group',where,null,null,order_by);
      return listData;
    },
    async getGroupUser(uid) {
      var table_cer = 'group_user';
      var where = {uid: {_eq: uid}};
      var gqLQueryList =await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key:where ,
        }
      });
      var listData = resData.data[table_cer];
      return listData;
    },
    /*async getAccount(uid) {
      var table_cer = 'accounts';
      var where = {uid: {_eq: uid}};
      var gqLQueryList =await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key:where ,
        }
      });
      var listData = resData.data[table_cer];
      return listData;
    },*/
    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      console.log(JSON.stringify(addressData));
      console.log(JSON.stringify(placeResultData));
      console.log(id);
    },
    backToList() {
      this.$emit('back');
    },
    async checkEmailAdminExist(email,model) {//check tồn tại sdt
      if (!this.$commonFuction.isEmpty(email)) {
        var where = {
          account: {'_eq': email},
          type: {'_eq': 'EMAIL'},
          deleted: {'_eq': false}
        }
        if (model !== undefined) where = Object.assign(where, {uid: {'_neq': model.id}})

        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        });

        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async validateData() {
      var group_id_va = document.getElementById("group_id_va");
      if (this.$commonFuction.isEmpty(this.user.group_id)) {
        group_id_va.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        group_id_va.setCustomValidity("");
      }

      var text_validator_email = "";
      if (!this.$commonFuction.isEmpty(this.user.email)) {
        console.log("this.model:",this.model);
        this.account_exist = await this.checkEmailAdminExist(this.user.email.trim(), this.model);
        if (this.account_exist) {
          text_validator_email = this.$constants.MSG_TEXT.EMAIL_EXIST_SYSTEM_MSG;
        }
      }

      var email = document.getElementById("email");
      if (!this.$commonFuction.isEmpty(text_validator_email)) {
        email.setCustomValidity(text_validator_email);
      } else {
        email.setCustomValidity("");
      }
      var password_confirm_text_va = "";
      if (!this.$commonFuction.isEmpty(this.user.password) && !this.$commonFuction.isEmpty(this.user.password_confirm)) {
        if (this.user.password !== this.user.password_confirm) {
          password_confirm_text_va = this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG;
          //password_confirm.setCustomValidity(this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG);
        }
      }
      var password_text_va = "";
      if (this.formMode !== 'add') {
        if (this.user.email != this.user.email_old){
          //trường hợp khác thì bắt nhập lại mật khẩu
          if (this.$commonFuction.isEmpty(this.user.password)){
            password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
          }
          if (this.$commonFuction.isEmpty(this.user.password_confirm)){
            password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
          }
        }else {
          //
          if (!this.$commonFuction.isEmpty(this.user.password)){
            //trường hợp thay đổi thì bắt nhập passcomfirt
            if (this.$commonFuction.isEmpty(this.user.password_confirm)){
              password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
            }
          }
          if (!this.$commonFuction.isEmpty(this.user.password_confirm)){
            //trường hợp thay đổi thì bắt nhập password
            if (this.$commonFuction.isEmpty(this.user.password)){
              password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
            }
          }
        }
      }
      var password_confirm = document.getElementById("password_confirm");
      if (!this.$commonFuction.isEmpty(password_confirm_text_va)){
        password_confirm.setCustomValidity(password_confirm_text_va);
      }else {
        password_confirm.setCustomValidity("");
      }

      var password = document.getElementById("password");
      if (!this.$commonFuction.isEmpty(password_text_va)){
        password.setCustomValidity(password_text_va);
      } else {
        password.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async getUserByPhoneNumber(phoneNumber) {
      // var phone = phoneNumber;
      // if (phoneNumber[0] === "0") {
      //   phone = phone.substring(1);
      // }
      // phone = phone.replace(' ', '');
      // phone = "+84" + phone;
      let query = `mutation MyMutation {
        checkPhoneNumber(phone: "${this.convertPhone(phoneNumber)}") {
          info
        }
      }`;
      await this.$apollo.mutate({
        mutation: gql(query)
      }).then(async(res) => {
        // console.log('=========> checkPhoneNumber: ', res)
        if (res) {
          this.firebaseID = res.data.checkPhoneNumber.info.uid
          await this.$apollo.query({
            query: DataServices.getList('users', {"fields" : "id"}),
            variables: {
              where_key: {
                phone: {'_eq': this.phone},
                deleted: {'_eq': false}
              }
            },
            fetchPolicy: "network-only"
          }).then((res) => {
            if (res && res.data.users.length > 0) this.userID = res.data.users[0].id
            else this.userID = null
            // console.log(this.userID)
          }).catch((error) => {
            console.log('ERROR: ', error.message)
          })
        }
      }).catch((error) => {
        console.log('ERROR: ', error.message)
        // CHƯA CÓ FIREBASE ID
        this.firebaseID = null
        this.userID = null
      })
    },
    async checkPhoneExist() {
      if (this.phone !== undefined && this.phone !== "") {
        // var phone = this.phone;
        // if (this.phone[0] === "0") {
        //   phone = phone.substring(1);
        // }
        // phone = phone.replace(' ', '');
        // phone = "+84" + phone;

        var where = {
          account: {'_eq': this.phone},
          type: {'_eq': 'PHONE'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async saveData() {

      /* if(this.saving == true){
         return;
       }
       this.saving = true;*/
      // this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }

      if (!this.account_exist) {
        var params = {
          user: this.user,
          images: this.images,
          imagesDeletes: this.imagesDeletes,
          // firebaseID: this.firebaseID,
          // userID: this.userID,
          // addressData:this.addressData,
          // placeResultData:this.placeResultData
          /*listCategory : this.selectedCategory,
          addCategory : this.addCategory,
          delCategory : this.delCategory,*/
        }
        console.log('saveData', params)
        this.$emit('save', params);
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Please upload the image file in the following format: jpeg, jpg, gif, png");
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }

          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === '') {
              this.imagesDeletes.push(this.images[0])
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData()
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === '') this.imagesDeletes.push(img)
    }
  },
  /*apollo: {
    category: {
      query() {
        return DataServices.getList('category', {"fields" : "id name"});
      },
      update(data) {
        if(data.category){
          this.categories = data.category
        }
      },
      fetchPolicy: "network-only"
    },
  }*/
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}

</style>
